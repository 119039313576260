html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}
.bgImage {
  min-height: 100vh;
  min-width: 100vw;
  object-fit: cover;
}
.iconFill {
  font-variation-settings: 'FILL' 1;
}

.footerHeading {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.footerHeadingText {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.iconTitle {
  margin-right: 5px;
}

.wrapperFacebook {
  font-size: 0.9rem;
  text-align: center;
  padding: 0.5rem 2rem;
  /*    py={'0.5rem'}
  ={'3rem'}
  border={'1px solid'}
  borderColor={'gray.200'}
  borderRadius={'5px'}
  backdropFilter={'auto'}
  backdropBlur={'5px'}
  */
} 

.wrapperFacebook:hover {
  background-color: rgb(39, 51, 126);
  border-radius: 0.5rem;
}

.iconFacebook {
  font-size: 1.5rem;
  background-color: white;
  color: #3b5998;
  border-radius: 50%;
  padding: 0.5rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
}

.linkFacebook {
  text-decoration: none;
}

.linkFacebook:hover {
  text-decoration: none;
  color: white;
}
.icon::before {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

  .square-facebook::before {
    font: var(--fa-font-solid);
    background-color: red;
    content: "f082";
  }

.menuItem {
  letter-spacing: 0.1rem;
  height: 1.7rem;
  vertical-align: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow: visible;
  white-space: nowrap;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  text-shadow: 0.1rem 0.1rem 0.1rem #282c34;
}

.menuItem:hover {
  background-color: rgba(55,75,95,0.5);
  border-radius: 0.5rem;
  color: white;
  cursor: pointer;
}

.phoneLink {
  text-decoration: none;
  font-weight: 800;
  cursor: pointer;
}

.phoneLinkIcon {
  color: white;
  font-weight: 600;
}
.phoneLinkIcon:before {
  font-size: 1.3em;
  font-weight: 700;
  margin-right: 0.2rem;
  vertical-align: middle;
  display: inline-block;
}

.phoneLinkIcon:hover {
  text-decoration: none;
  font-weight: bolder;
}

.emailLinkIcon {
  color: white;
  font-weight: 600;
}
.emailLinkIcon:before {
  font-size: 1.3em;
  font-weight: 700;
  margin-right: 0.2rem;
  vertical-align: middle;
  display: inline-block;
}

.imgFeature {
  display: block;
  position: relative;
  object-fit: cover;
  filter: drop-shadow(0 0.5rem 1rem #000);
  border-radius: 0.5rem;
  margin: 1.5rem auto;
}

.textEnhanced {
  font-weight: bold;
  font-size: 1.2rem;
  color: orange;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
  margin-bottom: 0.5rem;
}